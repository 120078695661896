<template>
  <section class="expendParts" id="metalcut">
    <v-container>
      <v-row>
        <v-col cols="12">
          <h2><span class="bold">Retrofit and repair:</span> pumps, motors and compressors</h2>
          <div class="orange-line center">
            <div class="orange"></div>
            <div class="line"></div>
          </div>
          <p>
            The process of implementing an industrial refurbishment project:
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="12" lg="12" class="d-none d-sm-flex">
          <img src="../../assets/Desktop Repair.svg" alt="">
        </v-col>
        <v-col cols="12" class="d-flex d-sm-none">
          <img src="../../assets/Mobile Repair.svg" alt="">
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {
  name: 'pump-repair',
  components: {
  }
}
</script>

<style>

</style>
