<template>
  <section class="repair">
    <general-second :name="page.pageName" :descript="page.description"></general-second>
    <pump-repair></pump-repair>
    <food-motors></food-motors>
  </section>
</template>

<script>
import PumpRepair from '@/components/repair/PumpRepair'
import FoodMotors from '@/components/food/food-motors'
import GeneralSecond from '@/components/GeneralSecond'
export default {
  name: 'Repair',
  components: {
    GeneralSecond,
    FoodMotors,
    PumpRepair
  },
  data () {
    return {
      page: {
        pageName: 'Services',
        description: ''
      }
    }
  },
  mounted () {
    if (Object.keys(this.$route.params).length > 0) {
      location.href = `${location.href}#${this.$route.params.id}`
    }
  },
  metaInfo: {
    title: 'Repair',
    titleTemplate: '%s - VERTEX GmbH',
    htmlAttrs: {
      lang: 'en',
      amp: true
    },
    meta: [
      { charset: 'utf-8' },
      { vmid: 'description', name: 'description', content: 'foo' }
    ]
  }
}
</script>

<style>

</style>
